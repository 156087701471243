import * as React from "react";

import MainStepper from "../../../Components/Stepper/MainStepper";
import Termsandcondition from "../../Onboarding/AddressScreens/Termsandcondition";
import SuccessScreen from "../../../Components/SuccessScreen/SuccessScreen";

const arraySteps = [{ title: "Terms and condition" }, { title: "Success" }]

const FinalizeOnboarding = () => {
  const [currentStep, setcurrentStep] = React.useState(0);

  const handleToUpdate = () => {
    setcurrentStep(currentStep + 1);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <Termsandcondition handleToUpdate={handleToUpdate} />;
      case 1:
        return <SuccessScreen
          title="Success"
          button="Visible"
          heading="Your application has been recieved and is now under review . We will notify when your account account is ready"
          redirectToHomePage="redirectToHomePage"
        />;
      default:
        return null;
    }
  };

  return (
    <MainStepper
      currentStep={currentStep}
      stepsArray={arraySteps}
    >
      {renderComponent()}
    </MainStepper>
  );
};

export default FinalizeOnboarding;
