import React, { useMemo } from "react";
import { AppContext } from "../../../Context/Context";

import Button from "../../../Components/Button/Button";
import { InvestorType, INVESTOR_TYPE } from "../../../Constant/onboardingData";
import SelectDropDown from "../../../Components/SelectDropdown/SelectDropDown";
import InvestorWorth from "../../../Components/investorCategory/InvestorCategorySelect";
import { Info } from "../../../Api/onBoarding";
import NonUsEntity from "../../../Components/NonUsInfo/NonUsEntity";
import NonUsIndividual from "../../../Components/NonUsInfo/NonUsIndividual";
import "./perfsonalInfoStyle.css";

function InvestorCategory({ handleToUpdate }: { handleToUpdate: () => void }) {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [dropdownDisable, setdropdownDisable] = React.useState(false);

  const {
    phone_Number,
    investorOptionSelect,
    firstName,
    lastName,
    email,
    company,
    citizenship,
    investorType,
    setinvestorType,
    isUSResident,
    userId,
  } = React.useContext(AppContext);

  const entityUser = firstName && lastName && email && company ? true : false;
  const individualUser = firstName && citizenship && email ? true : false;

  const result = useMemo(() => {
    if (isUSResident) {
      if (investorType === INVESTOR_TYPE.Individual && investorOptionSelect)
        return true;
      if (investorType === INVESTOR_TYPE.Entity && entityUser) return true;
    }
    if (!isUSResident) {
      if (investorType === INVESTOR_TYPE.Entity && entityUser) return true;
      if (investorType === INVESTOR_TYPE.Individual && individualUser)
        return true;
    } else {
      return false;
    }
  }, [
    isUSResident,
    investorType,
    entityUser,
    individualUser,
    investorOptionSelect,
  ]);

  React.useEffect(() => {
    InvestorTypeCheck();
    setError(false);
  }, [investorType]);

  const InvestorTypeCheck = async () => {
    if (investorType) {
      try {
        await Info.setInvestorType(+investorType, phone_Number, userId);
      } catch {
        setLoading(false);
      }
    }
  };

  const apiResponse = (response: any) => {
    if (response.message.success) {
      handleToUpdate();
    } else {
      setError(response.message.message);
      setLoading(false);
    }
  };

  const submitHandler = async () => {
    setdropdownDisable(true);
    setLoading(true);
    try {
      if (isUSResident) {
        if (investorType === INVESTOR_TYPE.Entity) {
          const response = await Info.submitEntityForm({
            company,
            email,
            firstName,
            lastName,
            userId,
          });
          console.log(response, "resss====>");
          apiResponse(response);
        }
        if (investorType === INVESTOR_TYPE.Individual) {
          const response = await Info.submitIndividualForm(
            userId,
            investorOptionSelect
          );
          apiResponse(response);
        }
      }
      if (!isUSResident) {
        if (investorType === INVESTOR_TYPE.Entity) {
          const response = await Info.submitEntityForm({
            company,
            email,
            firstName,
            lastName,
            userId,
          });
          apiResponse(response);
        }
        if (investorType === INVESTOR_TYPE.Individual) {
          const response = await Info.submitIndividualFormNoNUS({
            email,
            firstName,
            citizenship,
            userId,
          });
          apiResponse(response);
        }
      }
      setLoading(false);
    } catch {
      setLoading(true);
    }
  };
  const renderData = () => {
    if (isUSResident) {
      if (investorType === INVESTOR_TYPE.Individual) {
        return <InvestorWorth />;
      } else if (investorType === INVESTOR_TYPE.Entity) {
        return <NonUsEntity />;
      }
    } else if (!isUSResident) {
      if (investorType === INVESTOR_TYPE.Entity) {
        return <NonUsEntity />;
      } else if (investorType === INVESTOR_TYPE.Individual) {
        return <NonUsIndividual />;
      }
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <div className="header">
        <div className="title">
          <h3 className="title">What type of investor are you?</h3>
        </div>
        <div style={{ marginTop: 20 }}>
          <SelectDropDown
            items={InvestorType}
            captionKey="name"
            className="inputField"
            handler={setinvestorType}
            disable={dropdownDisable}
          />
        </div>
        {renderData()}
        <div>
          <h6 style={{ color: "#ff4d4d", marginTop: 20, textAlign: "center" }}>
            {" "}
            {error ? `* ${error}` : null}
          </h6>{" "}
        </div>
        <div className="continueBtn">
          <Button
            label="Continue"
            className={result ? "buttonContinue" : "deactiveButton"}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            disabled={!result}
            onClick={submitHandler}
          />
        </div>
      </div>
    </div>
  );
}

export default InvestorCategory;
