import React from "react";
import "./style.css";
import { AppContext } from "../../Context/Context";

interface RadioButtonProps {
  discription: string;
  id: number;
}

const RadioButton = ({ discription, id }: RadioButtonProps) => {
  const {investorOptionSelect, setinvestorOptionSelect } = React.useContext(AppContext);

  React.useEffect(() => {
    checkHanler();
  }, [investorOptionSelect]);

  const checkHanler = () => {
    setinvestorOptionSelect(investorOptionSelect.toString());
  };

  return (
    <div className="mainContainer">
      <div className="inputContainer">
        <input
          name="options"
          type="radio"
          className="input"
          id={id.toString()}
          value={id}
          onChange={(e) => setinvestorOptionSelect(e.target.value)}
        />
        <label className="labelText" htmlFor={id.toString()}>
          {discription}
        </label>
      </div>
    </div>
  );
};

export default RadioButton;
