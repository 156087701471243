import React, { useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { ApplicationStatus } from "../Constant/onboardingData";

import useAuthenticatedRoute from "./useAuthenticatedRoute";
import { Info, GetUserStatus } from "../Api/onBoarding";
import LoadingScreen from "../Screens/LoadingScreen/LoadingScreen";
import { AppContext } from "../Context/Context";
import { Routes } from "../Constant/Routes/Routes";

const PrivateRoutes = ({ path, component }: any) => {
  const [isLoading, setIsLoading] = useState(true);
  const { userId } = React.useContext<any>(AppContext);
  const history = useHistory();

  React.useEffect(() => {
    if (userId) {
      checkFlow().finally(() => setIsLoading(false));
    }
  }, [userId]);

  const checkFlow = async () => {
    const data = await GetUserStatus.fetchMainData(userId);
    try {
      const applicationStatus = data.message.application_status;
      const { message = {} } = await Info.GetUserDetails(userId);

      if (applicationStatus === ApplicationStatus.PENDING) {
        history.replace(Routes.HOME_SCREEN);
      } else {
        if (message.success) {
          const {
            data: {
              personalinfodata = {},
              kycinfodata = {},
              addressinfodata = {},
            },
          } = message;
          if (Object.keys(kycinfodata).length) {
            return history.replace(Routes.FINALIZE_ONBOARDING);
          } else if (Object.keys(addressinfodata).length) {
            return history.replace(Routes.IDENTIFICATION);
          } else if (Object.keys(personalinfodata).length) {
            return history.replace(Routes.ADDRESS_VERIFICATION);
          } else {
            return history.replace(Routes.PERSONAL_INFO_SCREEN);
          }
        }
      }
    } catch (error) {
      return history.replace(Routes.PERSONAL_INFO_SCREEN);
    }
  };

  const Component = component;
  const auth = useAuthenticatedRoute();

  return (
    <Route
      path={path}
      render={() => {
        if (isLoading) {
          return <LoadingScreen />;
        } else if (auth !== "true") {
          return <Redirect to={Routes.MAIN} />;
        } else {
          return <Component />;
        }
      }}
    />
  );
};

export default PrivateRoutes;
