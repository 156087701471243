import React from "react";
import InputMask from "react-input-mask";

import Button from "../../../Components/Button/Button";
import InputField from "../../../Components/Input/InputField/InputField";

import "./style.css";
import { Address } from "../../../Api/onBoarding";
import { AppContext } from "../../../Context/Context";

function AddressInformation({
  handleToUpdate,
}: {
  handleToUpdate: () => void;
}) {
  const [address1, setAddress1] = React.useState("");
  const [address2, setAddress2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, setStateName] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [states, setStates] = React.useState<any>([]);
  const { userId } = React.useContext(AppContext);
  const isInputEmpty = address1 && city && state && zipCode !== "";

  React.useEffect(() => {
    Address.get()
      .then((res) => {
        setStates(Object.values(res.question1[3].options));
      })
      .catch((err) => console.log("Failed!"));
  }, []);

  const handleSubmit = async () => {
    setLoading(true);

    const userAddressDetails = {
      address1,
      address2,
      city,
      state,
      zipCode,
      userId,
    };

    try {
      const response = await Address.submit(userAddressDetails);
      if (response.message.success) {
        handleToUpdate();
      } else {
        setLoading(true);
      }
    } catch (error: any) {
      handleToUpdate();
    }
  };
  return (
    <div>
      <div className="container">
        <div className="header">
          <div className="titleMainContainer">
            <h4 className="title">Enter your address</h4>
          </div>
          <div style={{ marginTop: 20 }}>
            <InputField
              type="text"
              placeholder="Address 1"
              name="address1"
              id="address1"
              value={address1}
              onChange={(e) => setAddress1(e.target.value)}
              className="inputField"
            />
            <InputField
              type="text"
              placeholder="Address 2"
              name="address2"
              id="address2"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              className="inputField"
            />
            <InputField
              type="text"
              placeholder="City"
              name="city"
              id="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              className="inputField"
            />
            <select
              value={state}
              className="inputField"
              onChange={(e) => setStateName(e.target.value)}
            >
              <option value="" disabled selected>
                Select state
              </option>
              {states.map((option: any, index: any) => {
                return (
                  <option key={index} value={option}>
                    {option}
                  </option>
                );
              })}
            </select>
            <InputMask
              mask="99999"
              className="inputField"
              maskChar={null}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder="Zip Code (must be 5 character)"
            />
          </div>
          <div className="continueBtn">
            <Button
              label="Continue"
              className={!isInputEmpty ? "deactiveButton" : "buttonContinue"}
              onClick={handleSubmit}
              disabled={!isInputEmpty}
              icon={
                loading ? (
                  <i className="fas fa-circle-notch fa-spin mt-2"></i>
                ) : null
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressInformation;
