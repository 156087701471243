class HttpRequest {
  get = async (url: string) => {
    return this.callAPI(url);
  };

  post = async (url: string, body: any | FormData) => {
    const obj = {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return this.callAPI(url, obj);
  };

  put = async (url: string, body: string) => {
    const obj = { method: "PUT", body };
    return this.callAPI(url, obj);
  };

  delete = async (url: string, body: string) => {
    const obj = { method: "DELETE", body };
    return this.callAPI(url, obj);
  };

  async callAPI(url: string, obj?: any) {
    try {
      const response = await fetch(url, obj);
      const responseJSON = await response.json();
      if (response.ok) {
        return { message: responseJSON, status: true };
      }
      if (response.status === 401) {
        return { message: {}, status: false };
      }
      return { message: responseJSON, status: false };
    } catch (err: any) {
      if (err && typeof err["isConnected"] === "boolean" && !err["isConnected"])
        return { message: err.message, status: false };
      return { message: err, status: false };
    }
  }
}
export default new HttpRequest();
