import { createContext } from 'react';


export interface IAppContext {
    firstName:string
    lastName:string
    email:string
    company:string
    citizenship:string
    investorType:string
    investorOptionSelect: any
    isUSResident: string
    phone_Number: string
    userId: any;
    token: string | null
    setusResident: (isUSResident: string | boolean) => void
    set_phone_number: (phone_Number: string) => void
    setUserId: (userId: number) => void
    setToken: (token: string |null) => void
    setinvestorOptionSelect: (investorOptionSelect: any) => void
    setFirstName: (firstName: string) => void
    setinvestorType: (investorType: string) => void
    setLastName: (lastName: string) => void
    setEmailAddress: (email: string) => void
    setCompany: (company: string) => void
    setCitizenship: (citizenship: string) => void
    
    
}

export const AppContext = createContext<IAppContext>({
    investorOptionSelect: '',
    isUSResident: '',
    phone_Number: '',
    userId: '',
    token: '',
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    citizenship: '',
    investorType: '',
    setusResident: () => { },
    set_phone_number: () => { },
    setUserId: () => { },
    setToken: () => { },
    setinvestorOptionSelect: () => { },
    setFirstName: () => { },
    setinvestorType: () => { },
    setLastName: () => { },
    setEmailAddress: () => { },
    setCompany: () => { },
    setCitizenship: () => { },
});