import React from "react";

import MainStepper from "../../../Components/Stepper/MainStepper";
import VerifiAddressScreen from "../../Onboarding/AddressScreens/VerifiAddressScreen";
import AddressInformation from "../../Onboarding/AddressScreens/AddressDetails";
import SuccessScreen from "../../../Components/SuccessScreen/SuccessScreen";
import { Routes } from "../../../Constant/Routes/Routes";

const arraySteps = [
  { title: "Verify Address" },
  { title: "Address Details" },
  { title: "Success" },
];

const AddressStepper = () => {
  const [currentStep, setcurrentStep] = React.useState(0);

  const handleToUpdate = () => {
    setcurrentStep(currentStep + 1);
  };

  const renderComponent = () => {
    switch (currentStep) {
      case 0:
        return <VerifiAddressScreen handleToUpdate={handleToUpdate} />;
      case 1:
        return <AddressInformation handleToUpdate={handleToUpdate} />;
      case 2:
        return (
          <SuccessScreen
            title="Success"
            heading="We have recieved your address details"
            navigate={Routes.IDENTIFICATION}
          />
        );
      default:
        return null;
    }
  };

  return (
    <MainStepper
      currentStep={currentStep}
      stepsArray={arraySteps}
    >
      {renderComponent()}
    </MainStepper>
  );
};

export default AddressStepper;
