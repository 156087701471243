export const InvestAmount = [
  {
    id: 1,
    discripton:
      "I had an individual income in excess of $200,000 in each of the two most recent years or joint income with spouse in excess of $300,000 in each of those year and I have a reasonable expectation of reaching the same income level in the current year",
  },
  {
    id: 2,
    discripton:
      "My individual net worth and joint net worth with my spouse, exceeds $1,000,000 (excluding the value of my primary residence)",
  },
  { id: 3, discripton: "none" },
];

export const CategoryTitle = [
  {
    id: 1,
    title: "Passport",
  },
  {
    id: 2,
    title: "Driver`s Licence",
  },
  {
    id: 3,
    title: "Identity Card",
  },
];

export const Status = [
  { id: "", name: "Select One" },
  { id: "Alabama", name: "Alabama" },
  { id: "Arizona", name: "Arizona" },
  { id: "EntAlaskaity", name: "EntAlaskaity" },
  { id: "Arkansas", name: "Arkansas" },
];

export const InvestorType = [
  { id: 1, name: "Entity" },
  { id: 2, name: "Individual" },
];
export const INVESTOR_TYPE = { Entity: "1", Individual: "2" };


export const ApplicationStatus = {
  NOT_STARTED: 'NOT STARTED', /// New user
  ONBOARDING_PENDING: 'ONBOARDING PENDING',///user kill tha app during onboarding
  PENDING: 'IN PROCESS',////when unboarding done
  RESUBMIT: 'RESUBMIT', ///reject onbording
  APPROVED: 'APPROVED',///user approved
}

export const onboardingApi = "3.129.36.235"