import * as React from "react";
import { useHistory } from "react-router-dom";
import OtpInput from "react-otp-input";
import { AppContext } from "../../../Context/Context";
// Custom components
import Button from "../../../Components/Button/Button";
import { authentication } from "../../../Api/authentication";
// CSS & images
import "./OTP.css";
import Icon from "../../../Assets/Images/otpIcon1.png";
import { Routes } from "../../../Constant/Routes/Routes";

interface OTPProp {
  stepLength: number;
}

const OTP: React.FC<OTPProp> = (stepLength) => {
  const { phone_Number, userId, setToken } = React.useContext(AppContext);
  const [code, setCode] = React.useState("");
  const [error, setError] = React.useState(false);
  const [counter, setCounter] = React.useState(60);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const push_token = "";

  React.useEffect(() => {
    if (stepLength.stepLength === 1) {
      counterHandler();
    }
  }, [counter, stepLength]);
  const counterHandler = () => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  };

  const ResendOtp = async () => {
    const response = await authentication.signIn({
      phone_number: phone_Number,
    });
    if (response.status) {
      setError(false);
      setCode("");
      alert("Succesfully send OTP");
      setCounter(60);
    } else {
      alert("Something Went Wrong");
    }
  };

  const handleConfirmation = async () => {
    setLoading(true);
    try {
      const response = await authentication.confirmation(
        userId,
        code,
        push_token
      );

      if (response.status) {
        setToken(response.message.token);
        sessionStorage.setItem("token", response.message.token);
        sessionStorage.setItem("isUserLogin", JSON.stringify(true));

        if (response.message.is_new_user) {
          await authentication.sendNewUserPhoneNumber({
            userId: userId,
            phoneNumber: phone_Number,
          });
        }
        setError(false);
        history.replace(Routes.PERSONAL_INFO_SCREEN);
      } else {
        setError(true);
        setLoading(false);
      }
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className="container1">
      <div className="header">
        <div className="mainHeader">
          <h4 className="mainTitle">Enter OTP</h4>
          <img src={Icon} alt="logo" />
        </div>
        <div style={{ marginTop: 20 }}>
          <h5 className="titleCode">Enter the code sent to {phone_Number}</h5>
        </div>
        <div className="otpContainer">
          <OtpInput
            value={code}
            onChange={setCode}
            inputStyle="inputFields"
            isInputNum
            separator={<span>-</span>}
          />
        </div>
        {error ? (
          <h6 className="errorText">The code was not correct , try again</h6>
        ) : null}
        <div className="continueBtn">
          <Button
            label="Verify"
            className={code.length > 3 ? "activeButton" : "deactiveButton"}
            disabled={code.length <= 3 || loading}
            onClick={handleConfirmation}
            icon={
              loading ? <i className="fas fa-circle-notch fa-spin"></i> : null
            }
          />
        </div>
        <div>
          <p className="footer">
            <button
              onClick={ResendOtp}
              disabled={counter !== 0 ? true : false}
              className="otpQuestionText"
            >
              Didn’t recieve a code?
            </button>
            you can resend in
            <span className="footerSpan">
              {" "}
              00-{counter < 10 ? `0${counter}` : counter}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default OTP;
