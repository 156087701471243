import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainStepper from "../Screens/Stepper";
import PersonalInfoScreen from "../Screens/Onboarding/PersonalInfo/PersonalInfoScreen";
import IdentificationStepper from "../Screens/AllSteppers/IdentificationStepper/IdentificationStepper";
import AddressStepper from "../Screens/AllSteppers/AdressStepper/AdressStepper";
import FinalizeOnboarding from "../Screens/AllSteppers/FinalizeOnboarding/FinalizeOnboarding";
import UserDetailsSection from "../Provider/contextProvider";
import PrivateRoutes from "../Authentication/PrivateRoutes";
import HomeScreen from "../Screens/HomeScreen/HomeScreen";
import "../App.css";

const Navigation = () => {
  return (
    <UserDetailsSection>
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <PrivateRoutes
            exact
            path="/PersonalInfo"
            component={PersonalInfoScreen}
          />
          <PrivateRoutes
            exact
            path="/Identification"
            component={IdentificationStepper}
          />
          <PrivateRoutes
            exact
            path="/AddressVerification"
            component={AddressStepper}
          />
          <PrivateRoutes
            exact
            path="/FinalizeOnboarding"
            component={FinalizeOnboarding}
          />
           <PrivateRoutes
            exact
            path="/HomeScreen"
            component={HomeScreen}
          />
          <Route path="/" component={MainStepper} />
        </Switch>
      </Router>
    </UserDetailsSection>
  );
};

export default Navigation;
