import React from "react";
import { KYC } from "../../../Api/onBoarding";
import Button from "../../../Components/Button/Button";
import FileUpload from "../../../Components/Input/FileUploadInput/FileUploadInput";
import { AppContext } from "../../../Context/Context";
import { isMobile } from "react-device-detect";
import Camera from "./OpenCamera";
type props = {
  title?: string;
  id?: number;
};

interface UploadDocumentsProps {
  handleToUpdate: () => void;
  documentTitle: props;
}

function UploadDocuments({
  handleToUpdate,
  documentTitle,
}: UploadDocumentsProps) {
  const [frontImage, setFrontImage] = React.useState<File | null>(null);
  const [backImage, setBackImage] = React.useState<File | null>(null);
  const [frontImageURL, setFrontImageURL] = React.useState("");
  const [backImageURL, setBackImageURL] = React.useState("");
  const [documentSide, setdocumentSide] = React.useState("front");
  const [loading, setLoading] = React.useState(false);
  let { userId } = React.useContext(AppContext);
  const { title: documentName, id: documentType } = documentTitle;
  const isInputEmpty =
    documentName === "Passport"
      ? frontImage === null
      : frontImage === null || backImage === null;

  const retakeImage = () => {
    setFrontImage(null);
    setFrontImageURL("");
    setBackImage(null);
    setBackImageURL("");
    setdocumentSide("front");
  };
  const submitForm = async (e: Event) => {
    e.preventDefault();
    setLoading(true);
    const data = {
      userId,
      documentType,
      frontImage,
      backImage,
    };

    try {
      const res = await KYC.submit(data);
      if (res.data.success) {
        handleToUpdate();
      } else {
        setLoading(true);
      }
    } catch (error) {
      setLoading(true);
    }
  };

  return (
    <div className="container">
      <div className="documentContainer">
        <div className="topContainer">
          <h1 className="title">{`Upload your ${documentName}`}</h1>
          {documentName === "Passport" ? (
            <div>
              {isMobile ? (
                !frontImage ? (
                  <Camera
                    setFrontImage={setFrontImage}
                    setFrontImageURL={setFrontImageURL}
                  />
                ) : (
                  <>
                    <img
                      src={frontImageURL}
                      alt="frontImage"
                      style={{ width: "100%" }}
                    />
                    <button onClick={retakeImage} className="backsideButton">
                      Try Again
                    </button>
                  </>
                )
              ) : (
                <>
                  <FileUpload onFileSelect={(file) => setFrontImage(file)} />
                  <h6 className="imageName">{frontImage?.name}</h6>
                </>
              )}
            </div>
          ) : (
            <>
              {isMobile ? (
                !frontImage || !backImage ? (
                  <>
                    <div className="titleMain">
                      <div>
                        <h4 className="heading">
                          {" "}
                          {documentSide === "front" && !frontImage
                            ? " Front of Card"
                            : "Back of Card"}
                        </h4>
                      </div>
                      {documentSide === "front" && !frontImage ? (
                        <Camera
                          setFrontImage={setFrontImage}
                          setFrontImageURL={setFrontImageURL}
                        />
                      ) : (
                        <Camera
                          setBackImage={setBackImage}
                          setBackImageURL={setBackImageURL}
                        />
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <img
                      src={frontImageURL}
                      alt="frontImage"
                      className="documentImage"
                    />
                    <img
                      src={backImageURL}
                      alt="frontImage"
                      className="documentImage"
                    />
                    <button onClick={retakeImage} className="backsideButton">
                      Try Again
                    </button>
                  </>
                )
              ) : (
                <>
                  <FileUpload
                    heading="Front of your Card"
                    onFileSelect={(file) => setFrontImage(file)}
                  />
                  <h6 className="imageName">{frontImage?.name}</h6>
                  <FileUpload
                    heading="Back of your Card"
                    onFileSelect={(file) => setBackImage(file)}
                  />
                  <h6 className="imageName">{backImage?.name}</h6>
                </>
              )}
            </>
          )}
        </div>
        <div className={!isMobile ? "button-cont-text" : ""}>
          <Button
            label="Continue"
            className={isInputEmpty ? "disableButton" : "buttonContinue"}
            disabled={isInputEmpty}
            icon={
              loading ? (
                <i className="fas fa-circle-notch fa-spin mt-2"></i>
              ) : null
            }
            onClick={submitForm}
          />
        </div>
      </div>
    </div>
  );
}

export default UploadDocuments;
