import React from "react";
import Button from "../../../Components/Button/Button";
import Logo from "../../../Assets/Images/logo.png";
import "./Info.css";
import { AppContext } from "../../../Context/Context";

const Info = ({ handleToUpdate }: { handleToUpdate: () => void }) => {
  const [selected, setselected] = React.useState<boolean | null>(null);
  const { setusResident } = React.useContext(AppContext);
  const clickHandler = () => {
    setusResident(selected!);
    sessionStorage.setItem("usResident", JSON.stringify(selected));
    handleToUpdate();
  };

  return (
    <div className="container1">
      <div className="header">
        <div className="mainHeader">
          <img src={Logo} alt="logo" />
          <h2 className="login-H2">Are you a US-resident?</h2>
        </div>

        <div className="btnContainer">
          <Button
            label="YES"
            className={selected ? "activeButtonText" : "buttonText"}
            icon={<i className="fas fa-check-circle Icon"></i>}
            onClick={() => setselected(true)}
          />
          <Button
            label="NO"
            className={
              selected === null || selected ? "buttonText" : "activeButtonText"
            }
            icon={<i className="fas fa-times-circle Icon"></i>}
            onClick={() => setselected(false)}
          />
        </div>
        <div className="continueBtn">
          <Button
            label="Continue"
            disabled={selected === null}
            className={selected === null ? "deactiveButton" : "buttonContinue"}
            onClick={clickHandler}
          />
        </div>

        <div>
          <p className="footer">
            Trouble logging in? <span className="footerSpan">Chat with us</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
