import * as React from "react";
import { useDropzone } from "react-dropzone";
import "./dropzone.css";
interface FileUploaderProps {
  heading?: string;
  onFileSelect: (file: File) => void;
}

const FileUpload = ({ heading, onFileSelect }: FileUploaderProps) => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  React.useEffect(() => {
    if (acceptedFiles.length) {
      handleFileInput(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const handleFileInput = (e: React.FormEvent | any) => {
    if (acceptedFiles.length) {
      onFileSelect(e);
      acceptedFiles.length = 0;
    } else {
      const target = e.target as HTMLInputElement;
      const file = (target.files as FileList)[0];
      onFileSelect(file);
    }
  };

  return (
    <div className="documentTitleContainer">
      {heading ? <h4 className="heading">{heading}</h4> : null}
      <div {...getRootProps({ className: "dropzone" })}>
        <input
          {...getInputProps()}
          onChange={handleFileInput}
          accept="image/png, image/gif, image/jpeg"
        />
        <div className="dropzoneContainer">
          <p className="dropzoneContainerContent">
            Drag & drop or <span className="span">browse</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
