import { env } from "../Constant/env";
import HttpRequest from "./httpRequest";

class Authentication {
  signIn = async (inputData: any) => {

    const url = `${env.ENDPOINT}/carl/login/`;
    const body = JSON.stringify(inputData);
    return HttpRequest.post(url, body);
  };
  confirmation = async (user_id: string, token: string, push_token: any) => {

    const url = `${env.ENDPOINT}/carl/otp/verify/`;
    const body = JSON.stringify({
      user_id,
      token,
      push_token,
      device_info: "Web",
      device_id: "D47954D8-BAFD-448B-A0D6-54B11926D527"

    });

    return HttpRequest.post(url, body);
  };

  resendOtpCode = async (user_id: any) => {
    const url = `${env.ENDPOINT}/carl/otp/reset/`;
    const body = JSON.stringify({ user_id });
    return HttpRequest.post(url, body);
  };

  isUserAuthenticated = async () => {
    const url = `${env.ENDPOINT}/carl/verify-token/`;
    return HttpRequest.get(url);
    
  };

  signOutDevices = async (inputData: any) => {
    const url = `${env.ENDPOINT}/carl/signout-devices/`;
    const body = JSON.stringify(inputData);
    return HttpRequest.put(url, body);
  
  };

  signInByTouchOrFaceID = async () => {
    const url = `${env.ENDPOINT}/carl/refresh-token/`;
    return HttpRequest.get(url);
  
  };
  sendNewUserPhoneNumber = async (inputData: any) => {

    const url = `${env.ONBOARING_ENDPOINT}/sendPhoneNumber`;
    const body = JSON.stringify(inputData);
    return HttpRequest.post(url, body);
  };

  sendCodeToEmail = async (user_id: any) => {
    const url = `${env.ENDPOINT}/carl/otp/send-to-email/`;
    const body = JSON.stringify({ user_id });
    return HttpRequest.post(url, body);
  
  };
  verifyPin = async (inputData: any) => {
    const url = `${env.ENDPOINT}/verify-pin/`;
    const body = JSON.stringify(inputData);
    return HttpRequest.post(url, body);
  };

  verifyBetaTestCode = async (inputData: any) => {
    const url = `${env.ENDPOINT}/carl/verify-code/`;
    const body = JSON.stringify(inputData);
    return HttpRequest.post(url, body);
   
  };
}
const authentication = new Authentication();

export { authentication };
